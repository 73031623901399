




































import { Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import { ReporterRolePermissionsModel, UserAccountLookupModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import { BaseFormComponent, ValidationSummaryCpt, ValidationHintCpt, SpinnerCpt } from '@/core/components';
import { InputOrganisationsCpt, InputOrganisationStudiesCpt } from '@/modules/admin/users/components';

@Component({
  components: {
    InputOrganisationsCpt,
    InputOrganisationStudiesCpt,
    ValidationSummaryCpt,
    ValidationHintCpt,
    SpinnerCpt,
  },
})
export default class ManageStudyAdminFormCpt extends BaseFormComponent {
  @Prop() userId: number;
  @Prop() lookups: UserAccountLookupModel;
  @Prop({ default: false }) disabled: boolean;

  model = new ReporterRolePermissionsModel({ modelState: {} } as any);
  loading = true;

  async created() {
    try {
      await this.initializeModel();
    } finally {
      this.loading = false;
    }
  }

  async onSubmitForm() {
    try {
      await ApiService.users().saveReporterRolePermissions(this.model).handleValidationErrors(this.model);

      this.$notify({
        title: this.$t('msg.success') as string,
        message: this.$t('msg.settingsSaved') as string,
        type: 'success',
      });
    } finally {
      // Have to do this re-mapping to break the reference which then triggers "Access from" and "Access to" validation
      (this.model as any).modelState = { ...(this.model as any).modelState };
    }
  }

  onReporterOrganisationsChanged(selectedIds: number[]) {
    this.model.reporterOrganisations =
      this.model.reporterOrganisations?.filter(p => selectedIds.includes(p.organisationId)) ?? [];

    selectedIds.forEach(selectedId => {
      if (!this.model.reporterOrganisations!.find(p => p.organisationId === selectedId)) {
        const organisation = this.lookups.organisations!.find(p => p.id === selectedId);
        if (!organisation) {
          return;
        }

        this.model.reporterOrganisations!.push({
          organisationId: organisation.id,
          organisationName: organisation.name,
          studyTypeId: organisation.studyTypeId,
          allowMaleData: false,
          allowFemaleData: false,
          allowInjuryAndIllnessData: false,
          allowExposureData: false,
          allowCatastrophicData: false,
          studies: [],
        });
      }
    });
  }

  private async initializeModel() {
    const model = (await ApiService.users().getReporterRolePermissions(this.userId)).data;
    _.extend(this.model, model);
  }
}
