

























import { Component, Prop } from 'vue-property-decorator';

import { InputDateCpt, InputSelectCpt } from '@/core/components';
import {
  UserAccountLookupModel,
  ReporterRolePermissionsModel,
  ReporterAccessRequestLookupModel,
  ReporterAccessRequestModel,
} from '@/core/webapi';
import AppVue from '@/AppVue.vue';
import { StudyTypes } from '@/core/constants';
import InputOrganisationPermissionsCpt from './InputOrganisationPermissionsCpt.vue';

@Component({
  components: {
    InputDateCpt,
    InputSelectCpt,
    InputOrganisationPermissionsCpt,
  },
})
export default class InputOrganisationsCpt extends AppVue {
  @Prop() model: ReporterRolePermissionsModel | ReporterAccessRequestModel;
  @Prop() lookups: UserAccountLookupModel | ReporterAccessRequestLookupModel;
  @Prop({ default: false }) disabled: boolean;
  @Prop() onReporterOrganisationsChanged: (selectedIds: number[]) => void;
  @Prop() enforceOrganisationId: number | null;
  @Prop() orgAccessLevelLabel: string;

  // Internal model is needed because the Element SELECT component works with an array
  // of IDs, but we need to work with an array of objects
  internalModel = {
    organisations: [] as number[],
    modelState: {},
  };
  eliteId = StudyTypes.Elite;
  communityId = StudyTypes.Community;

  get organisationsPlaceholder() {
    if (!this.lookups?.organisations?.length) {
      return this.$t('lbl.noOrganisationsAvailable');
    }

    if (!this.disabled) {
      return this.$t('lbl.pleaseSelectOrganisations');
    }

    if (this.disabled && this.model.reporterOrganisations && !this.model.reporterOrganisations.length) {
      return this.$t('lbl.noOrganisationsSelected');
    }

    return '';
  }

  get accessLevelLabel() {
    if (!this.isAuthenticated) {
      return this.$t('lbl.requiredAccessLevelWithinOrganisation');
    }

    if (this.$route.name === 'route.userProfile') {
      return this.$t('lbl.currentOrganisationAccessLevel');
    }

    if (this.isMedicalOrSiteAdmin && this.$route.name === 'reporter-access-request') {
      return this.$t('lbl.requestedAccessLevelWithinOrganisation');
    }

    return this.$t('lbl.accessLevelWithinTheOrganisation');
  }

  created() {
    this.internalModel.organisations =
      this.model.reporterOrganisations?.filter(p => !!p.organisationId).map(p => p.organisationId!) ?? [];
  }

  onReporterOrganisationsChangedInternal(selectedIds: number[]) {
    const attemptRemovalOfEnforcedOrg =
      !!this.enforceOrganisationId && !selectedIds.includes(this.enforceOrganisationId);
    if (attemptRemovalOfEnforcedOrg) {
      // If the user tries to remove it, re-add it. You still need to implement a $notify()
      // message inside the onReporterOrganisationsChanged in the parent component.
      this.internalModel.organisations.unshift(this.enforceOrganisationId!);
    }

    this.$emit('onReporterOrganisationsChanged', selectedIds, attemptRemovalOfEnforcedOrg);
  }
}
