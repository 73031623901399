





























import { Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import {
  BaseFormComponent,
  InputSelectCpt,
  ValidationSummaryCpt,
  ValidationHintCpt,
  SpinnerCpt,
} from '@/core/components';
import { StudyAdminRolePermissionsModel, UserAccountLookupModel } from '@/core/webapi';
import { ApiService } from '@/core/services';

@Component({
  components: {
    InputSelectCpt,
    ValidationSummaryCpt,
    ValidationHintCpt,
    SpinnerCpt,
  },
})
export default class ManageStudyAdminFormCpt extends BaseFormComponent {
  @Prop() userId: number;
  @Prop() lookups: UserAccountLookupModel;

  model = new StudyAdminRolePermissionsModel({ modelState: {} } as any);
  loading = true;

  async created() {
    try {
      await this.initializeModel();
    } finally {
      this.loading = false;
    }
  }

  async onSubmitForm() {
    try {
      await ApiService.users().saveStudyAdminRolePermissions(this.model).handleValidationErrors(this.model);

      this.$notify({
        title: this.$t('msg.success') as string,
        message: this.$t('msg.settingsSaved') as string,
        type: 'success',
      });
    } finally {
      // Have to do this re-mapping to break the reference which then triggers "Access from" and "Access to" validation
      (this.model as any).modelState = { ...(this.model as any).modelState };
    }
  }

  private async initializeModel() {
    const model = (await ApiService.users().getStudyAdminRolePermissions(this.userId)).data;
    _.extend(this.model, model);
  }
}
