






























































































































import { Component, Prop } from 'vue-property-decorator';
import VueFriendlyCaptcha from '@somushq/vue-friendly-captcha';

import AppVue from '@/AppVue.vue';
import { ApiService } from '@/core/services';
import { InputFieldCpt, InputSelectCpt, ValidationSummaryCpt, ValidationHintCpt, SpinnerCpt } from '@/core/components';
import {
  ReporterAccessRequestLookupModel,
  ReporterAccessRequestModel,
  ReporterAccessRequestOrganisationPermissionsModel,
} from '@/core/webapi';
import { ReporterRequestStatus } from '@/core/constants';
import { InputOrganisationsCpt, InputOrganisationStudiesCpt } from '@/modules/admin/users/components';
import InputOrganisationCommentsAndActionsCpt from './InputOrganisationCommentsAndActionsCpt.vue';

@Component({
  components: {
    InputFieldCpt,
    InputSelectCpt,
    InputOrganisationsCpt,
    InputOrganisationStudiesCpt,
    InputOrganisationCommentsAndActionsCpt,
    ValidationSummaryCpt,
    ValidationHintCpt,
    VueFriendlyCaptcha,
    SpinnerCpt,
  },
})
export default class ReporterAccessRequestCpt extends AppVue {
  @Prop() statusId: number;
  @Prop() orgAccessLevelLabel: string;

  captchaSiteKey = process.env.VUE_APP_CAPTCHA_SITE_KEY;
  lookups = {} as ReporterAccessRequestLookupModel;
  model = new ReporterAccessRequestModel({
    inviteKey: this.$route.params.inviteKey,
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    reporterOrganisations: [],
    modelState: {},
  } as any);
  loading = true;
  submitInProgress = false;
  isCaptchaValid = false;

  reporterRequestStatus = ReporterRequestStatus;

  get showCreateAccountButton() {
    return (
      this.isAdmin && this.model.isSpamChecked && this.model.reporterOrganisations!.every(x => x.isMedicalAdminApproved)
    );
  }

  get showCaptcha() {
    // TODO: Also don't show for dev to not waste verification attempts, but keep it for now
    return !this.loading && !this.isAuthenticated && !!this.lookups.organisations?.length;
  }

  get isSubmitButtonEnabled() {
    return this.isAuthenticated || this.isCaptchaValid;
  }

  async created() {
    this.model.inviteKey = this.$route.params.inviteKey;

    try {
      if (this.$route.params.requestId) {
        this.model = (await ApiService.reporterAccess().getReporterAccessRequest(+this.$route.params.requestId)).data;
      }

      this.lookups = (await ApiService.reporterAccess().getReporterAccessRequestLookupData(this.model.inviteKey)).data;

      if (this.lookups.preselectedOrganisationId && !this.model.requestId) {
        this.addOrganisationToModel(this.lookups.preselectedOrganisationId);
      }
    } finally {
      this.loading = false;
    }
  }

  async submitRequest() {
    this.submitInProgress = true;
    try {
      await ApiService.reporterAccess().requestReporterAccess(this.model).handleValidationErrors(this.model);
      this.$notify({
        title: this.$t('msg.success') as string,
        message: this.$t('msg.requestSubmittedSuccessfullyAndAwaitingApproval') as string,
        type: 'success',
      });
      this.$router.push('/login');
    } finally {
      this.submitInProgress = false;
    }
  }

  async forwardToMAs() {
    this.submitInProgress = true;
    try {
      await ApiService.reporterAccess().forwardToMedicalAdmins(this.model.requestId);
      this.$notify({
        title: this.$t('msg.success') as string,
        message: this.$t('msg.requestForwardedToMAs') as string,
        type: 'success',
      });
      this.$router.push('/admin/reporter-access-requests');
    } finally {
      this.submitInProgress = false;
    }
  }

  async rejectRequestAsSpam() {
    if (!confirm(this.$t('confirm.areYouSureYouWantToRejectAndDeleteThisRequestAsSpam') as string)) {
      return;
    }

    this.loading = true;
    try {
      await ApiService.reporterAccess().rejectReporterRegistrationRequest({
        requestId: this.model.requestId,
        rejectionReason: 'Spam',
      });
      this.$notify({
        title: this.$t('msg.success') as string,
        message: this.$t('msg.requestRejected') as string,
        type: 'success',
      });
      this.$router.push('/admin/reporter-access-requests');
    } finally {
      this.loading = false;
    }
  }

  async rejectRequest() {
    this.$prompt(this.$t('lbl.pleaseProvideRejectionReason') as string, this.$t('lbl.rejectRequest') as string, {
      confirmButtonText: this.$t('btn.submit') as string,
      cancelButtonText: this.$t('btn.cancel') as string,
    })
      .then(async (result: any) => {
        this.loading = true;

        try {
          await ApiService.reporterAccess().rejectReporterRegistrationRequest({
            requestId: this.model.requestId,
            rejectionReason: result.value,
          });
          this.$notify({
            title: this.$t('msg.success') as string,
            message: this.$t('msg.requestRejected') as string,
            type: 'success',
          });

          this.$router.push('/admin/reporter-access-requests');
        } finally {
          this.loading = false;
        }
      })
      .catch(_ => {});
  }

  async saveOrganisationRequest(organisation: ReporterAccessRequestOrganisationPermissionsModel) {
    this.loading = true;
    try {
      const redirectToGrid = this.model.reporterOrganisations?.every(p => p.isMedicalAdminApproved);
      await ApiService.reporterAccess().updateOrganisationMedicalAdminStatus(organisation);

      this.$notify({
        title: this.$t('msg.success') as string,
        message: this.$t('msg.organisationAccessRequestUpdated') as string,
        type: 'success',
      });

      if (redirectToGrid) {
        this.$router.push('/admin/reporter-access-requests');
      }
    } finally {
      this.loading = false;
    }
  }

  async rejectOrganisationRequest(organisationRequestId: number) {
    if (!confirm(this.$t('confirm.reporterAccessRequestRejection') as string)) {
      return;
    }

    this.loading = true;
    try {
      const redirectToGrid = this.model.reporterOrganisations?.length === 1;
      await ApiService.reporterAccess().rejectReporterOrganisationAccessRequest({
        organisationRequestId,
        rejectionReason: 'TODO: IMPLEMENT',
      });

      this.$notify({
        title: this.$t('msg.success') as string,
        message: this.$t('lbl.organisationAccessRequestRejected') as string,
        type: 'success',
      });

      if (redirectToGrid) {
        this.$router.push('/admin/reporter-access-requests');
      } else {
        this.model = (await ApiService.reporterAccess().getReporterAccessRequest(+this.$route.params.requestId)).data;
      }
    } finally {
      this.loading = false;
    }
  }

  async createAccount() {
    this.submitInProgress = true;
    try {
      if (!this.isAuthenticated) {
      }

      const userId = (
        await ApiService.reporterAccess().createReporterAccount(this.model).handleValidationErrors(this.model)
      ).data;

      this.$notify({
        title: this.$t('msg.success') as string,
        message: this.$t('msg.accountCreatedSuccessfully') as string,
        type: 'success',
      });

      this.$router.push(`/admin/users/${userId}/edit`);
    } finally {
      this.submitInProgress = false;
      // Have to do this re-mapping to break the reference which then triggers "Access from" and "Access to" validation
      (this.model as any).modelState = { ...(this.model as any).modelState };
    }
  }

  onReporterOrganisationsChanged(selectedIds: number[], attemptRemovalOfEnforcedOrg = false) {
    this.model.reporterOrganisations =
      this.model.reporterOrganisations?.filter(p => !!p.organisationId && selectedIds.includes(p.organisationId)) ?? [];

    selectedIds.forEach(selectedId => {
      if (!this.model.reporterOrganisations!.find(p => p.organisationId === selectedId)) {
        this.addOrganisationToModel(selectedId);
      }
    });

    if (attemptRemovalOfEnforcedOrg) {
      this.$notify({
        title: this.$t('msg.warning') as string,
        message: this.$t('lbl.organisationYouWereInvitiedToCantBeRemoved') as string,
        type: 'warning',
      });
    }
  }

  onCaptchaDone(solution: string) {
    this.isCaptchaValid = (this.$refs.captchaComponent as any).isValid();
    this.model.captchaSolution = this.isCaptchaValid ? solution : '';
  }

  onCaptchaError(error: string) {
    this.isCaptchaValid = false;
    this.model.captchaSolution = '';
  }

  private addOrganisationToModel(organisationId: number, useUnshift = false) {
    const organisation = this.lookups.organisations!.find(p => p.id === organisationId);
    if (!organisation) {
      return;
    }

    // Using unshift to keep the "inviteKey org" the first one in the list
    this.model.reporterOrganisations![useUnshift ? 'unshift' : 'push']({
      organisationRequestId: undefined as any,
      organisationId: organisation.id,
      organisationName: organisation.name,
      studyTypeId: organisation.studyTypeId,
      reporterComment: '',
      medicalAdminComment: '',
      allowMaleData: false,
      allowFemaleData: false,
      allowInjuryAndIllnessData: false,
      allowExposureData: false,
      allowCatastrophicData: false,
      isMedicalAdminApproved: false,
    });
  }
}
